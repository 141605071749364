import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { addSidepotEntry } from 'src/app/store/actions/entries.actions';
import { AppState } from 'src/app/store/reducers/AppState';
import { groupBy, mergeMap, Observable, toArray, from } from 'rxjs';
import { selectEntries } from 'src/app/store/selectors/entries.selectors';
import { EventData } from 'src/app/core/interfaces/EventData';
import { selectEventClasses } from 'src/app/store/selectors/classes.selectors';
import { selectClassDateEntriesEntities } from 'src/app/store/selectors/classDateEntries.selectors';
import { selectSidepotEntriesEntities } from 'src/app/store/selectors/sidepotEntries.selectors';
import { ContestantEntry } from 'src/app/core/interfaces/Entry';
import { selectRolloverEntriesEntities } from 'src/app/store/selectors/rolloverEntries.selectors';

@Component({
  selector: 'mef-entry-classes',
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.scss']
})
export class ClassesComponent implements OnInit {
  @Input() eventData: EventData;
  @Input() contestant: ContestantEntry;

  classes$: Observable<any[]>;
  entriesGrouped: any = {};
  sidepotEntries: any = {};
  rolloverEntries: any = {};
  classDateEntries: any = {};

  constructor(private store: Store<AppState>) {

    this.store.select(selectEntries).subscribe(entries => {
      this.entriesGrouped = {};

      from(entries).pipe(
        groupBy(entry => entry.contestantId),
        mergeMap(group => group.pipe(toArray()))
      ).subscribe(group => {
        
        from(group).pipe(
          groupBy(child => child.classId),
          mergeMap(group2 => group2.pipe(toArray()))
        ).subscribe(childGroup => {

          if (!this.entriesGrouped[group[0]['contestantId']]) {
            this.entriesGrouped[group[0]['contestantId']] = {};
          }

          this.entriesGrouped[group[0]['contestantId']][childGroup[0]['classId']] = childGroup;
        
        })


      });




    });

    this.classes$ = this.store.select(selectEventClasses);

    this.store.select(selectClassDateEntriesEntities).subscribe(classDateEntries => {
      this.classDateEntries = classDateEntries;
    });

    this.store.select(selectSidepotEntriesEntities).subscribe(sidepotEntries => {
      this.sidepotEntries = sidepotEntries;
    });

    this.store.select(selectRolloverEntriesEntities).subscribe(rolloverEntries => {
      this.rolloverEntries = rolloverEntries;
    });


  }



  ngOnInit(): void {

  }
  



}
