import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { EventService } from 'src/app/core/services/event.service';
import { getEventFeesFailure, getEventFeesSuccess } from '../actions/event-fees.actions';
import { getEvent, getEventSuccess, getEventFailure } from '../actions/event.actions';

@Injectable()
export class EventEffects {

    constructor(
        private actions$: Actions,
        private eventService: EventService
    ) { }

    getEvent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getEvent),
            exhaustMap(action =>
                this.eventService.getEvent(action.id).pipe(
                    map(event => getEventSuccess({ data: event })),
                    catchError((error: any) => of(getEventFailure(error))))
            )
        )
    });

    // getEventFees$ = createEffect(() => {
    //     return this.actions$.pipe(
    //         ofType(getEvent),
    //         exhaustMap(action =>
    //             this.eventService.getAllEventFees(action.id).pipe(
    //                 map(data => getEventFeesSuccess({ data: data })),
    //                 catchError((error: any) => of(getEventFeesFailure(error))))
    //         )
    //     )
    // });
}