import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ContestantEntry } from 'src/app/core/interfaces/Entry';
import { addClassDateEntry, removeClassDateEntry } from 'src/app/store/actions/entries.actions';
import { AppState } from 'src/app/store/reducers/AppState';
import * as moment from 'moment';

@Component({
  selector: 'mef-entry-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit {
  @Input() horseId: string;
  @Input() item: any;
  @Input() checked: boolean;
  @Input() classId: string;
  @Input() classData: any;
  @Input() contestant: ContestantEntry;
  @Input() multipleGos: boolean;
  @Input() disabled: boolean = false;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
  }

  updateDateSelectionEntry(horseEntryId: string, classId: string, priceId: string, date: string) {
    if (this.checked) {
      this.removeDateSelectionEntry(horseEntryId, priceId, date);
      
      if (this.multipleGos) {
        this.removeDateSelectionEntry(horseEntryId, priceId,  moment(date, "MM-DD-YYYY").add(1, 'days').format('MM-DD-YYYY').toString());
      }
    
    } else {
      this.addDateSelectionEntry(horseEntryId, classId, priceId, date);

      if (this.multipleGos) {
        this.addDateSelectionEntry(horseEntryId, classId, priceId,  moment(date, "MM-DD-YYYY").add(1, 'days').format('MM-DD-YYYY').toString());
      }

    }
  }

  addDateSelectionEntry(horseEntryId: string, classId: string, priceId: string, date: string) {
    this.store.dispatch(addClassDateEntry({ contestantId: this.contestant.id, entryId: horseEntryId, classId: classId, priceId: priceId, date: date }));
  }

  removeDateSelectionEntry(horseEntryId: string, priceId: string, date: string) {
    this.store.dispatch(removeClassDateEntry({ entryId: horseEntryId, priceId: priceId, date: date }));
  }
}
