import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ContestantEntry } from 'src/app/core/interfaces/Entry';
import { addSidepotEntry, removeSidepotEntry } from 'src/app/store/actions/entries.actions';
import { AppState } from 'src/app/store/reducers/AppState';

@Component({
  selector: 'mef-entry-sidepot',
  templateUrl: './sidepot.component.html',
  styleUrls: ['./sidepot.component.scss']
})
export class SidepotComponent implements OnInit {
  @Input() horseId: string;
  @Input() item: any;
  @Input() checked: boolean;
  @Input() classId: string;
  @Input() contestant: ContestantEntry;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
  }

  updateSidepotEntry(horseEntryId: string, sidepotId: string) {
    if (this.checked) {
      this.removeSidepotEntry(horseEntryId, sidepotId);
    } else {
      this.addSidepotEntry(horseEntryId, sidepotId, this.classId);
    }
  }

  addSidepotEntry(horseEntryId: string, sidepotId: string, classId: string) {
    this.store.dispatch(addSidepotEntry({ contestantId: this.contestant.id, entryId: horseEntryId, sidePotId: sidepotId, classId: classId }));
  }

  removeSidepotEntry(horseEntryId: string, sidepotId: string) {
    this.store.dispatch(removeSidepotEntry({ entryId: horseEntryId, sidePotId: sidepotId }));
  }

}
