import { ActionReducerMap } from "@ngrx/store";
import { classesReducer } from "./classes.reducers";
import { contactInfoReducers } from "./contact-info.reducers";
import { classEntriesReducer } from "./classEntries.reducers";
import { eventReducers } from "./event.reducers";
import { sidepotEntriesReducer } from "./sidepotEntries.reducers";
import { additionalItemsReducer } from "./additional-items.reducers";
import { additionalItemPurchasesReducer } from "./additional-item-purchases.reducers";
import { notesReducers } from "./notes.reducers";
import { checkoutPriceReducers } from "./checkout-price.reducers";
import { eventFeesReducers } from "./eventFees.reducers";
import { classDatesReducer } from "./classDates.reducers";
import { contestantEntriesReducer } from "./contestantEntries.reducers";
import { contestantValidationsReducer } from "./contestantValidations.reducers";
import { paymentInfoReducers } from "./payment-info.reducers";
import { canNavigateReducers } from "./can-navigate.reducer";
import { rolloverEntriesReducer } from "./rolloverEntries.reducers";
import { classEntryValidationsReducer } from "./classEntryValidations.reducers";
import { isEditReducers } from "./is-edit.reducer";

export interface AppState {
    event: any;
    contactInfo: any;
    classes: any;
    contestantValidations: any;
    contestantEntries: any;
    classEntries: any;
    classEntryValidations: any;
    classDateEntries: any;
    sidepotEntries: any;
    rolloverEntries: any;
    additionalItems: any;
    additionalItemPurchases: any;
    notes: any;
    eventFees: any;
    checkoutPriceResult: any;
    paymentInfo: any;
    canNavigate: any;
    isEdit: any;
}

export const reducers: ActionReducerMap<AppState> = {
    event: eventReducers,
    contactInfo: contactInfoReducers,
    classes: classesReducer,
    contestantValidations: contestantValidationsReducer,
    contestantEntries: contestantEntriesReducer,
    classEntries: classEntriesReducer,
    classEntryValidations: classEntryValidationsReducer,
    classDateEntries: classDatesReducer,
    sidepotEntries: sidepotEntriesReducer,
    rolloverEntries: rolloverEntriesReducer,
    additionalItems: additionalItemsReducer,
    additionalItemPurchases: additionalItemPurchasesReducer,
    notes: notesReducers,
    eventFees: eventFeesReducers,
    checkoutPriceResult: checkoutPriceReducers,
    paymentInfo: paymentInfoReducers,
    canNavigate: canNavigateReducers,
    isEdit: isEditReducers
};
