<div class="mt-6">
  <div *ngIf="displayTotals">
    <div>
      <div *ngFor="let item of checkoutPriceResult.appliedFees" class="flex justify-between text-base font-medium text-gray-900">
        <p>{{item.name}}</p>
        <p>{{item.price | currency}}</p>
      </div>
    </div>
    <div *ngIf="paidEvent" class="flex justify-between text-base font-medium text-gray-900">
      <p>Processing Fee</p>
      <p>{{checkoutPriceResult.processingFee | currency}}</p>
    </div>
    <div *ngIf="!paidEvent && !rodeo && !bbrBarrel" class="flex justify-between text-base font-medium text-gray-900">
      <p>Processing Fee</p>
      <p>{{5 * checkoutPriceResult.contestants | currency}}</p>
    </div>
    <div class="flex justify-between text-base font-medium text-gray-900">
      <p>Subtotal</p>
      <p>
        {{checkoutPriceResult.price / 100 | currency}}
      </p>
    </div>
  </div>
  <div *ngIf="additionalText" class="mt-6 flex justify-between text-base text-gray-700">
    <p>
      {{additionalText}}
    </p>
  </div>
  <div *ngIf="!disableButtons">
    <div class="mt-6">
      <button [disabled]="isInvalid" (click)="continue()"
        class="w-full flex justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white"
        [ngClass]="isInvalid == false ? 'bg-emerald-600 hover:bg-emerald-700' : 'bg-gray-400 hover:bg-emerald-500'">Continue</button>
    </div>
    <div class="mt-6 flex justify-center text-sm text-center text-gray-500">
      <p>
        or <button (click)="back()" type="button" class="text-emerald-600 font-medium hover:text-emerald-500"><span
            aria-hidden="true"> &larr;</span>{{backLabel}}</button>
      </p>
    </div>
  </div>
</div>