<div>
    <div>
        <div class="-ml-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
            <div class="ml-4">
                <h3 class="text-md text-gray-900">
                    {{item.name}}
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                    {{item.description}}
                </p>
            </div>
            <div class="ml-4 flex-shrink-0">
                <button type="button" *ngIf="!eventData.rodeo" (click)="addEntryToClass(item['_id'])"
                    class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                    Add Horse
                </button>

                <button type="button" *ngIf="eventData.rodeo" (click)="addEntryToClass(item['_id'])"
                    class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                    Enter
                </button>
            </div>
        </div>
    </div>

    <div class="divide-y divide-gray-200 mt-4">

        <div *ngFor="let horseEntry of getClassEntries(item._id); trackBy:custom" class="bg-gray-50 p-4">
            <div class="flex">
                <div>

                    <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-baseline">
                        <div *ngIf="!eventData.rodeo" class="sm:col-span-2">
                            <label for="horse_name" class="block text-sm font-medium text-gray-700">Horse</label>
                            <div class="mt-1">
                                <input type="text" name="horse_name" id="horse_name"
                                    class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    placeholder="Name" [ngModel]="horseEntry['horseName']"
                                    (ngModelChange)='updateHorseName(horseEntry, $event)'>
                            </div>
                        </div>

                        <div *ngIf="!eventData['rodeo'] && !eventData['bbrBarrel']" class="mt-4 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg">
                                <p class="text-sm text-gray-500">Select the days you wish to run.</p>
                                <div class="col-span-3 sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start pt-5">
                                    <div *ngFor="let price of item.prices; index as i;">
                                        <div class="flex items-center">

                                            <mef-entry-date [contestant]="contestant" [horseId]="horseEntry.id"
                                                [classId]="item['_id']" [multipleGos]="item.multipleGos" [item]="price"
                                                [classData]="item"
                                                [checked]="classDateEntries[horseEntry.id + price._id + price.date]?.selected">
                                            </mef-entry-date>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="eventData['bbrBarrel']" class="mt-4 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg">
                                <p class="text-sm text-gray-500">Preselected Dates</p>
                                <div class="col-span-3 sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start pt-5">
                                    <div *ngFor="let price of item.prices; index as i;">
                                        <div class="flex items-center">

                                            <mef-entry-date [contestant]="contestant" [horseId]="horseEntry.id"
                                                [classId]="item['_id']" [multipleGos]="item.multipleGos" [item]="price"
                                                [classData]="item"
                                                [checked]="classDateEntries[horseEntry.id + price._id + price.date]?.selected"
                                                [disabled]="true">
                                            </mef-entry-date>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div *ngIf="item.sidePots.length" class="mt-4 mb-4 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg">
                                <p *ngIf="!eventData.rodeo && !eventData.bbrBarrel" class="text-sm text-gray-500">Sidepots</p>
                                <p *ngIf="eventData.rodeo" class="text-sm text-gray-500">You may only enter each class
                                    once.
                                </p>
                                <p *ngIf="eventData['bbrBarrel']" class="text-sm text-gray-500">Select Sidepots/Preferred Draw section below if applicable
                                </p>
                                <div class="mt-4 space-y-4">

                                    <mef-entry-sidepot *ngFor="let sidePot of item.sidePots; index as i;"
                                        [contestant]="contestant" [horseId]="horseEntry.id" [classId]="item['_id']"
                                        [item]="sidePot"
                                        [checked]="sidepotEntries[horseEntry.id + sidePot._id]?.selected">
                                    </mef-entry-sidepot>

                                </div>
                            </div>
                        </div>

                        <!-- Rollover Section -->

                        <div *ngIf="item.rolloverOptions.length" class="mt-4 mb-4 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg">
                                <p class="text-sm text-gray-500">Select rollovers below.</p>
                                <div class="mt-4 space-y-4">

                                    <mef-entry-rollover *ngFor="let rollover of item.rolloverOptions; index as i;"
                                        [contestant]="contestant" [multipleGos]="item.multipleGos"
                                        [horseId]="horseEntry.id" [classId]="item['_id']" [item]="rollover"
                                        [horseName]="horseEntry['horseName']"
                                        [checked]="rolloverEntries[horseEntry.id + rollover._id]?.selected">
                                    </mef-entry-rollover>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="ml-auto pl-3">
                    <div class="-mx-1.5 -my-1.5">
                        <button type="button" (click)="removeEntry(horseEntry.id)"
                            class="inline-flex bg-gray-50 rounded-md p-1.5 text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-600">
                            <span class="sr-only">Dismiss</span>
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clip-rule="evenodd" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>