import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { ClassDateEntry } from "src/app/core/interfaces/Entry";
import { addClassDateEntry, removeClassDateEntry, removeContestantEntry, removeEntry } from "../actions/entries.actions";
import { clearEntries } from "../actions/can-navigate.action";

export interface ClassDatesState extends EntityState<ClassDateEntry> { }

export const classDatesAdapter: EntityAdapter<ClassDateEntry> = createEntityAdapter<ClassDateEntry>();
export const initialClassDatesState: ClassDatesState = classDatesAdapter.getInitialState();
export const classDatesReducer = createReducer(
    initialClassDatesState,
    on(addClassDateEntry, (state, action) => classDatesAdapter.addOne({ id: action.entryId + "" + action.priceId + "" + action.date, contestantId: action.contestantId, entryId: action.entryId, priceId: action.priceId, classId: action.classId, date: action.date, selected: true }, state)),
    on(removeClassDateEntry, (state, action) => classDatesAdapter.removeOne(action.entryId + "" + action.priceId + "" + action.date, state)),
    on(removeContestantEntry, (state, action) => classDatesAdapter.removeMany(entity => entity.contestantId === action.contestantId, state)),
    on(clearEntries, (state, action) => classDatesAdapter.removeAll({ ...state, id: null })),
    on(removeEntry, (state, action) => classDatesAdapter.removeMany(entity => entity.entryId === action.entryId, state)),
    );

export const { selectAll, selectEntities } = classDatesAdapter.getSelectors();